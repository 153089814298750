<template>
  <points-line
    v-if="getPaginationAnimation"
    :class="{ back: addClassToPagination }"
    class="animation"
  />

  <div v-else :class="{ back: addClassToPagination }" class="pagination">
    <a
      v-if="!checkFirstButton"
      href="#"
      class="pagination__button"
      @click.prevent="setFirstOrLastIndex(true)"
    >
      <img src="/icons/pagination/arrow-double.svg" class="pagination__icon" />
    </a>

    <a
      v-for="(button, index) in buttons"
      href="#"
      :key="index"
      @click.prevent="checkPage(button)"
      :class="{ 'active-button': button.index === currentButton }"
      class="pagination__button"
    >
      {{ button.index }}
    </a>

    <a
      href="#"
      v-if="checkLastButton"
      class="pagination__button"
      @click.prevent="setFirstOrLastIndex(false)"
    >
      <img
        src="/icons/pagination/arrow-double.svg"
        class="pagination__icon pagination__icon_last-arrow"
      />
    </a>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PointsLine from '@/components/atom/loader/PointsLine.vue';

export default {
  props: {
    target: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    PointsLine,
  },
  computed: {
    ...mapGetters('vkRequests', ['getMaxButtons', 'needResetButton', 'getPaginationAnimation']),
    returnButtons() {
      const buttons = [this.buttonA, this.buttonB, this.buttonC];
      return buttons;
    },

    checkFirstButton() {
      return this.buttons[0].index === this.currentButton;
    },

    checkLastButton() {
      return this.getMaxButtons !== this.currentButton;
    },
  },
  watch: {
    needResetButton(to) {
      if (to === true) {
        this.buttons = [
          {
            name: 'buttonA',
            index: 1,
          },
          {
            name: 'buttonB',
            index: 2,
          },
          {
            name: 'buttonC',
            index: 3,
          },
        ];
        this.currentButton = 1;
      }
    },
    target(to) {
      if (to !== null && this.observer !== null) {
        this.observer.observe(this.target);
      }
    },
  },
  data() {
    return {
      observer: null,
      currentButton: 1,
      anotherRequest: false,
      addClassToPagination: false,
      buttons: [
        {
          name: 'buttonA',
          index: 1,
        },
        {
          name: 'buttonB',
          index: 2,
        },
        {
          name: 'buttonC',
          index: 3,
        },
      ],
    };
  },
  methods: {
    ...mapActions('vkRequests', ['requestPage', 'requestPagePagination']),

    checkPage(buttonItem, inf = false) {
      this.anotherRequest = inf;
      let increment = 0;
      let addNewIndex = false;

      switch (true) {
        case buttonItem.index === this.currentButton:
          return;
        case this.getMaxButtons <= 3:
          increment = buttonItem.index - this.currentButton;
          this.currentButton = buttonItem.index;
          this.requestNextPage(increment);
          return;
        case buttonItem.name === 'buttonA':
          increment = buttonItem.index - this.currentButton;
          addNewIndex = buttonItem.index !== 1;
          this.currentButton = buttonItem.index;
          this.requestNextPage(increment, addNewIndex);
          return;

        case buttonItem.name === 'buttonB':
          increment = buttonItem.index - this.currentButton;
          this.currentButton = buttonItem.index;
          this.requestNextPage(increment, false);
          break;
        case buttonItem.name === 'buttonC':
          increment = buttonItem.index - this.currentButton;
          addNewIndex = buttonItem.index !== this.getMaxButtons;
          this.currentButton = buttonItem.index;
          this.requestNextPage(increment, addNewIndex);
          break;
        default:
          addNewIndex = this.currentButton !== this.getMaxButtons && this.currentButton !== 1;
          this.currentButton += 1;
          this.requestNextPage(1, addNewIndex);
      }
    },

    setFirstOrLastIndex(isFirst = false) {
      this.currentButton = isFirst ? 1 : this.getMaxButtons;

      document.getElementById('header').scrollIntoView(true);

      this.buttons.forEach((element, ind) => {
        if (isFirst) {
          this.buttons[ind].index = ind + 1;

          this.requestPage(-10);
          return;
        }

        this.buttons[this.buttons.length - ind - 1].index = this.getMaxButtons - ind;

        this.requestPage(10);
      });
    },

    requestNextPage(setCount, newIndex = false) {
      if (newIndex) {
        const updated = setCount > 0 ? 1 : -1;
        for (let i = 0; i < this.buttons.length; i += 1) {
          this.buttons[i].index += updated;
        }
      }
      if (this.anotherRequest) {
        this.requestPagePagination();
        return;
      }

      this.requestPage(setCount);
      document.getElementById('header').scrollIntoView(true);
    },

    setNextButton() {
      const forCheck = this.currentButton + 1;

      this.checkPage({ index: forCheck }, true);
    },
  },

  mounted() {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
    };

    this.observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        this.addClassToPagination = false;
      }
      if (!entry.isIntersecting) {
        this.addClassToPagination = true;
      }
    }, options);
  },

  unmounted() {
    this.observer.disconnect();
  },
};
</script>

<style scoped lang="scss">
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;

  /* .pagination__button */
  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
    border-bottom: 4px solid transparent;

    text-decoration: none;
    color: #000000;

    height: 30px;

    cursor: pointer;
  }

  &__icon {
    width: 15px;
    height: 15px;

    &_last-arrow {
      transform: rotate(180deg);
    }
  }
}

.active-button {
  border-bottom: 4px solid #2b587a;
  border-radius: 0;
  color: #2b587a;
}

.animation {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.back {
  background: #127ddb29;
}
</style>
